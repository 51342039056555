
























































@import '~variables'

.plan-viewer
  display flex
  flex-direction column
  padding 0.1px
  width 100%
  height 100%
  .nav-info
    margin $space-2 $space-2 0
    .back-button-arrow
      transform rotate(90deg)
    .building-name
      font-weight $fw-bold
  .building-viewer
    z-index 1
    height 100%
    @media screen and (max-width: 500px)
      -webkit-overflow-scrolling touch
      -webkit-transform translateZ(0px)
      -webkit-transform translate3d(0, 0, 0)
      -webkit-perspective 1000
