@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.plan-viewer {
  display: flex;
  flex-direction: column;
  padding: 0.1px;
  width: 100%;
  height: 100%;
}
.plan-viewer .nav-info {
  margin: 16px 16px 0;
}
.plan-viewer .nav-info .back-button-arrow {
  transform: rotate(90deg);
}
.plan-viewer .nav-info .building-name {
  font-weight: 700;
}
.plan-viewer .building-viewer {
  z-index: 1;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .plan-viewer .building-viewer {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000;
  }
}
/*# sourceMappingURL=src/app/pages/data/views/plan-viewer.css.map */